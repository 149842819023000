import React, { useEffect, useContext, useState } from "react"
import { graphql } from "gatsby"
import ReviewerLayout from "../../gatsby-theme-carbon/components/ReviewerLayout";
import AnswersReview from "../../components/AnswersReview";
import { NssspContext } from "../../gatsby-theme-carbon/components/Layout";
import { Button, InlineNotification, Toggle, TextInputSkeleton, ToggleSkeleton, InlineLoading, Dropdown, DataTableSkeleton, TextArea, Loading } from "carbon-components-react";
import { CheckmarkOutline32, Pending32 } from '@carbon/icons-react'
import { fetchAuthenticatedContent, UserContext } from '@parallelpublicworks/entitysync'

// const SubmitButton = SubmitButtonComponent(({onClick, disabled, buttonLabel, kind = "primary", id, ...props}) => {
//   return <Button onClick={(e) => onClick(e)} kind={kind} disabled={disabled} id={id}>{buttonLabel}</Button>
// });

function Applicant({ data, params, location, ...props }) {

  const userContext = useContext(UserContext)
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null
  const userData = userState && userState.userData ? userState.userData : {}

  const nsssp = useContext(NssspContext);

  
  const [source, setSource] = useState({type: 'node--applicant_rate', attributes: {}});
  const [locked, setLocked] = useState(true);
  const [saved, setSaved] = useState(false);
  const [errorAlert, setErrorAlert] = useState();

  const [loading, setLoading] = useState(false)
  const [posting, setPosting] = useState(false)

  const [errors, setErrors] = useState({});

  const { fetchUserAnswers } = useContext(NssspContext);

  useEffect(() => {
    params?.uid && fetchUserAnswers(params.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    (async () => {
      if(nsssp?.answers?.answers?.id && typeof nsssp?.answers?.answers?.id === 'string' && nsssp?.answers?.answers?.id.length > 0) {
        let _source = source || {};
          setSource({
            ..._source,
            type: 'node--applicant_rate',
            relationships: {
              field_applicant_answer: {
               data: {
                 type: "node--answers",
                 id: nsssp.answers.answers.id
               }
             }
            }
           })
           
          if(locked === true && params?.uid){
            setLocked(1)
            await fetchRate(params.uid)
            setLocked(false)
          }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nsssp])

  async function fetchRate(idApplicantUser) {
    if(idApplicantUser && userData){
      try {
        let endpoint = `node/applicant_rate/?filter[applicant][condition][path]=field_applicant_answer.uid.id`
        endpoint += `&filter[applicant][condition][operator]=%3D`
        endpoint += `&filter[applicant][condition][value]=${idApplicantUser}`
        setLoading(true)
        const resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET');
        setLoading(false)
        if(resp){
          if(resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
            const { data } = resp;
            let rate = data.find(item => item.relationships.uid.data.id === userState.currentUserId)
            setSource(rate)
          }
        }
      } catch (error) {
        setErrorAlert('fetchRate ERROR ' + error.message)
        setLocked(null)
      }
    }
  }

  const onSubmit = async (e, saveAndSubmit) => {
    e.preventDefault();
      
    if(!source.attributes) source.attributes = {}
    source.attributes.title =  `Rating by ${nsssp?.user.attributes.field_full_name} for ${nsssp.answers?.answers?.attributes?.field_first_name}${nsssp.answers?.answers?.attributes?.field_middle_initial ? (' ' + nsssp.answers?.answers?.attributes?.field_middle_initial) : ''} ${nsssp.answers?.answers?.attributes?.field_last_name}`;
     

    if(source.attributes.field_abstained) {
      source.attributes.field_rating = 0;
      if(!source.attributes.field_abstain_comment) {
        setErrors({...errors, field_abstain_comment: 'Please provide a comment'})
        return
      }
    } else {
      source.attributes.field_abstained = false;
      source.attributes.field_abstain_comment = '';
    }
    
    if(!source.relationships) {
      source.relationships = {
        field_applicant_answer: {
          data: {
            type: "node--answers",
            id: nsssp.answers.answers.id
          }
        }
       }
    }

    if(saveAndSubmit) source.attributes.field_rating_submitted = true;

    // !source.id ? save(source) : edit(source)
    post(source)
  }

  const post = async (source) => {
    let endpoint = `node/applicant_rate`
    if(source.id) endpoint += `/${source.id}`
    let action = source.id ? 'PATCH' : 'POST'
    setPosting(true)
    try {
      const resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, action, { data: source });

      if(resp && resp.data) {
        setSource(resp.data)
        setSaved(true)
      } else if(resp === false) {
        console.log('post resp false')
        console.log(resp)
        setErrorAlert('fetchRate ERROR ' + resp?.message);
      }

      setPosting(false)
    } catch (error) {
      setPosting(false)
      setErrorAlert(error.message)
    }
  }

  const modifySubmitted = async (e) => {
    e.preventDefault();

    source.attributes.field_rating_submitted = false;
  
    let endpoint = `node/applicant_rate/${source.id}`

    setPosting(true)
    
    const resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'PATCH', { data: source });
    
    setPosting(false)

    setSource(resp.data)
  }
  
  let items = [
    {
      id: 5.00,
      label: '5.00 - Do not recommend'
    },
    {
      id: 4.75,
      label: '4.75'
    },
    {
      id: 4.50,
      label: '4.50'
    },
    {
      id: 4.25,
      label: '4.25'
    },
    {
      id: 4.00,
      label: '4.00 - Borderline, only if funds are plentiful'
    },
    {
      id: 3.75,
      label: '3.75'
    },
    {
      id: 3.50,
      label: '3.50'
    },
    {
      id: 3.25,
      label: '3.25'
    },
    {
      id: 3.00,
      label: '3.00 - Acceptable for fellowship'
    },
    {
      id: 2.75,
      label: '2.75'
    },
    {
      id: 2.50,
      label: '2.50'
    },
    {
      id: 2.25,
      label: '2.25'
    },
    {
      id: 2.00,
      label: '2.00 - Recommend for fellowship'
    },
    {
      id: 1.75,
      label: '1.75'
    },
    {
      id: 1.50,
      label: '1.50'
    },
    {
      id: 1.25,
      label: '1.25'
    },
    {
      id: 1.00,
      label: '1.00 - Exceptional Candidate, must receive fellowship'
    },
  ];
  let SacaleComponent = <Dropdown
                          ariaLabel="Dropdown"
                          id="carbon-dropdown-example"
                          items={items}
                          label={
                            !loading && !source?.attributes?.field_rating && !source?.attributes?.field_abstained ?
                            'No rating selected.' : ''
                          }
                          titleText="Rating:"
                          disabled={source?.attributes?.field_rating_submitted}
                          selectedItem={ source?.attributes?.field_rating ? ('' + source?.attributes?.field_rating) : ''}
                          onChange={(value) => {
                            let { attributes = {} } = source;
                            attributes['field_rating'] = value?.selectedItem?.id;
                            setSource({ ...source, attributes: { ...attributes } });
                            setSaved(false);
                          }}
                        />
                        
                        
  const { loadingAnswers } = nsssp;
  
  if(locked) return <Loading />

  // console.warn('SOURCE?', source);
  return (
    <ReviewerLayout>
      {saved &&
        <section>
          <InlineNotification
            className=""
            kind="success"
            title="Saved"
            subtitle=""
            onCloseButtonClick={() => setSaved(false)}
          />
        </section>}
        {errorAlert &&
        <section>
          <InlineNotification
            className=""
            kind="error"
            title="Error"
            subtitle={errorAlert}
            onCloseButtonClick={() => setErrorAlert()}
          />
        </section>}
      <div className="task-review complete" style={{ paddingBottom: '3rem' }}>
        <form onSubmit={(e) => {e.preventDefault()}}> 
          <h3>
            {source?.attributes?.field_rating_submitted ? <CheckmarkOutline32 /> : <Pending32 />}
            <span>Rate applicant</span>
            {!loading ? (<Toggle
              id="field_abstained"
              field="field_abstained"
              labelText="Abstain rating"
              size="md"
              labelA=""
              labelB=""
              className="toggle-abstained"
              toggled={source?.attributes?.field_abstained === true}
              onToggle={(value) => {
                let { attributes = {} } = source;
                attributes['field_abstained'] = value;

                if(value) {
                  attributes['field_rating'] = '';
                }

                setSource({ ...source, attributes: { ...attributes } });
                setSaved(false);
              }}
              disabled={source?.attributes?.field_rating_submitted === true}
            />) : (
              <ToggleSkeleton className="toggle-abstained-skeleton" />
            )}
          </h3>
          {
            source?.attributes?.field_abstained !== true ?
            <div className="slider-container">
              {!loading ? (SacaleComponent) : (
                <TextInputSkeleton />
              )}
            </div> :
            <div className="padd">
              <TextArea
                // helperText="Comment"
                disabled={source?.attributes?.field_rating_submitted === true}
                id="field_abstain_comment"
                name="field_abstain_comment"
                invalid={errors && errors['field_abstain_comment']}
                invalidText={errors && errors['field_abstain_comment']}
                labelText="Abstain comment"
                placeholder="Please post a comment"
                value={source?.attributes?.field_abstain_comment}
                onChange={(e) => {
                  e.preventDefault();
                  let { attributes = {} } = source;
                  attributes[e.target.name] = e.target.value;
                  setSource({ ...source, attributes: { ...attributes } });
                  if(e.target.value) {
                    delete errors[e.target.name];
                    setErrors({ ...errors })
                  } else {
                    setErrors({...errors, [e.target.name]: 'Please provide a comment'})
                  }
                  setSaved(false);
                }}
              />
            </div>
          }
          <div className="button-group">
            {
              posting ? (
                <InlineLoading
                  className="loading-submit"
                  description={source?.attributes?.field_rating_submitted === true ? "Submitted" : "Submitting"}
                  status={source?.attributes?.field_rating_submitted === true ? 'finished' : 'active'}
                />
              ) :  (
                <div className="button-group">
                  {
                    source?.attributes?.field_rating_submitted && (
                        <Button
                          type="button"
                          kind="secondary"
                          onClick={modifySubmitted}
                        >
                          Edit submission
                        </Button>
                      )
                  }
                  <Button
                    type="button"
                    kind="primary"
                    onClick={(e) => onSubmit(e, true)}
                    disabled={loading || posting || source?.attributes?.field_rating_submitted === true || Object.keys(errors).length > 0 || (!source?.attributes?.field_rating && !source?.attributes?.field_abstained)}
                  >
                    {
                      source?.attributes?.field_rating_submitted ? 'Submitted' : 'Save and submit'
                    }
                  </Button>
                </div>
                )
            }
          </div>
        </form>
      </div>
      {
        (loadingAnswers || !nsssp?.answers?.answers || !data?.allNodeTask?.edges)
          ? <DataTableSkeleton /> : 
          <>
            <div className="task-review complete" style={{ paddingBottom: '1rem' }}>
              <h3>
                <CheckmarkOutline32 /> 
                <span>
                  {`${nsssp.answers?.answers?.attributes?.field_first_name}${nsssp.answers?.answers?.attributes?.field_middle_initial ? (' ' + nsssp.answers?.answers?.attributes?.field_middle_initial) : ''} ${nsssp.answers?.answers?.attributes?.field_last_name}` || ''}
                </span>
              </h3>
            </div>
            {
              data.allNodeTask.edges.map(({ node }) => {
                return (
                  <AnswersReview key={node.id} task={node} isReviewerView />
                );
              })
            }
          </>
      }
    </ReviewerLayout>
  )
}

export default function ApplicantPage({ pageContext, location, data, ...props }) {
  pageContext = { ...pageContext, frontmatter: { title: "Applicant" } };
  return (
    <Applicant {...props} pageContext={pageContext} location={location} data={data} />
  )
}

export const query = graphql`
{
  allNodeTask(sort: {fields: title, order: ASC}) {
    edges {
        node {
            id
            drupal_id
            title
            field_description {
              value
            }
            internal {
              type
            }
            relationships {
              field_questions {
                ... on node__text_question {
                  drupal_id
                  title
                  field_is_required
                  field_identifier
                  field_type
                  field_number_type
                  internal {
                    type
                  }
                }
                ... on node__checkbox_question {
                  drupal_id
                  title
                  field_is_multiple
                  field_is_required
                  field_identifier
                  internal {
                    type
                  }
                  relationships {
                    field_options {
                      drupal_id
                      title
                    }
                    field_invalid_options {
                      drupal_id
                    }
                  }
                }
                ... on node__address_question {
                  drupal_id
                  field_identifier
                  field_is_required
                  field_exclude_fields
                  title
                  internal {
                    type
                  }
                }
                ... on node__file_question {
                  drupal_id
                  title
                  field_is_required
                  field_identifier
                  internal {
                    type
                  }
                }
                ... on node__lor_question {
                  drupal_id
                  title
                  field_number_of_letters 
                  internal {
                    type
                  }
                }
              }
            } 
        }
    }
  }
}
`


